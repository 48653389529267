import {getEnvironment360URL} from '../configs/appConfig';

function openInDefaultBrowser(url: string) {
    try {
        // Create a new anchor element
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank'; // Ensure it's a new tab, forcing the system's default browser
        document.body.appendChild(a);  // Append the anchor to the DOM
        a.click();  // Programmatically trigger the click
        document.body.removeChild(a);  // Clean up
    } catch (error) {
        console.error("Failed to open in default browser:", error);
    }
}

export const openLink = (link: string, event?: React.MouseEvent<HTMLElement>): void => {
    link = getEnvironment360URL() + "/"+link;
    if (event) {
        event.preventDefault();
    }

    console.error('From Desktop Link Open');
    openInDefaultBrowser(link);
};
