import { IDocumentDetails , IMailInfo} from "../../@types/models";
import fetchClient from "./fetchClient";
import {getEnvironment360BizURL,getFetchApiOptions, ApiConfig} from '../configs/appConfig';

export const findAndGetMailInfo = (successCb: (resData: IDocumentDetails | null) => void, errorCb: (err: string) => void,docRecno: string | null) => {

    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async asyncResult => {

        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            const mailInfo: IMailInfo = { restUrl: GetRestUrl(), serviceToken: asyncResult.value, itemId: GetItemRestId(), docRecno: docRecno };
            await getMailDetails(mailInfo,successCb,errorCb);
        } else {
            errorCb(asyncResult.error.message);
        }
    });

};

export const archiveMail = (successCb: (recno: string | null) => void, errorCb: (err: string) => void) => {

    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async asyncResult => {

        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            const mailInfo: IMailInfo = { restUrl: GetRestUrl(), serviceToken: asyncResult.value, itemId: GetItemRestId(), docRecno: null };
            archiveMailInfo(mailInfo,successCb,errorCb);
        } else {
            errorCb(asyncResult.error.message);
        }
    });

};
const archiveMailInfo = async (mailInfo: IMailInfo, sCB :(res:string)=>void, eCB : (err : string)=>void) => {
    try {
        const token = await getAccessToken();
        const response = await fetchClient(getEnvironment360BizURL() +
            ApiConfig.SaveMail, {
                body: JSON.stringify(mailInfo),
                headers: {
                    "Accept": "application/json",
                    "Accept-Language": document.documentElement.lang,
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                method: "POST",
                ...getFetchApiOptions
            });
        sCB(response as string);
        } catch (err) {
            eCB("Error archiving mail: "+ err);
        }
  };

export const getAccessToken = async () =>{

    const accessToken = await OfficeRuntime.auth.getAccessToken({allowSignInPrompt: true,
        allowConsentPrompt: true,
        forMSGraphAccess: true,
        })
        .catch(function (err) {
            throw new Error("code: "+ err?.code +" and message " + err?.message);
        });

        return accessToken;
}

export const getMailDetails = async (mailInfo: IMailInfo ,sCB: (resData: IDocumentDetails | null) => void, eCB: (err: string) => void) => {

    try {
            const token = await getAccessToken();
            const response = await fetchClient(getEnvironment360BizURL() +
            ApiConfig.GetArchivedMailInfo, {
                body: JSON.stringify(mailInfo),
                headers: {
                    "Accept": "application/json",
                    "Accept-Language": document.documentElement.lang,
                    "Authorization": `Bearer ${token}`,
                    "ClientId": "d2915e32-ef77-48da-87b0-943b7b6939b0",
                    "Content-Type": "application/json"
                },
                method: "POST",
                ...getFetchApiOptions
            });
            sCB(response)
      } catch (err) {
        eCB("Error archiving mail: "+ err);
      }
};

export const getConfigs = async () => {

    try {
        const token = await getAccessToken();
        const response = await fetchClient(getEnvironment360BizURL() +
        ApiConfig.getConfig, {
        body: {},
        headers: {
            "Accept": "application/json",
            "Accept-Language": document.documentElement.lang,
            "Authorization": `Bearer ${token}`,
            "ClientId": "d2915e32-ef77-48da-87b0-943b7b6939b0",
            "Content-Type": "application/json"
        },
        method: "POST",
        ...getFetchApiOptions
      });

        return response;
      } catch (err) {
        return null;
      }
};



export const GetRestUrl = (): string => {
    return Office.context.mailbox.restUrl + '/v2.0';
};

/**
 * get the rest itemId from office
 */
export const GetItemRestId = (): string => {
    const ewsId = Office.context.mailbox.item?.itemId;
    if (ewsId)
        return Office.context.mailbox.convertToRestId(ewsId, Office.MailboxEnums.RestVersion.v2_0);
    return ''; /*Office.context.mailbox.item may be undefined in the latest version; whereas it could not in the previous version.
        Returning empty string should be enough and server will return proper error message to show in ui.
    */
};