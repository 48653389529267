import React,{ReactNode} from 'react';
import {makeStyles} from "@fluentui/react-components";
import { QuestionCircle24Regular,Globe24Regular } from '@fluentui/react-icons';
import { getEnvironment360URL } from '../../services/configs/appConfig';
import i18n from '../../i18n';
import { getConfigs } from '../../services/api/dataService';
import { IConfigInfo } from '../../@types/models';
import { formatVersionForHelpUrl } from '../../utils/Helper';

const useStyles = makeStyles({
    headerItems: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
     },
     item:
     {
        padding:'2px'
     },
     itemHide:{
        padding:'2px',
        visibility:'hidden'
     },
     root:
     {
       minWidth:"280px",
       maxWidth:"320px",
     },
     childrenroot:
     {
        display:'flex',
        minHeight:"579px",
        alignItems:"center",
        flexDirection:"column"
     }
  });


const openLink = (link: string, event?: React.MouseEvent<HTMLElement>): void => {
    if (event) {
        event.preventDefault();
    }
    // Force to open in default browser if outlook app client is beeing used
    if ((Office.context.diagnostics.platform === Office.PlatformType.PC || Office.context.diagnostics.platform === Office.PlatformType.Mac) && Office.context.diagnostics.host === Office.HostType.Outlook && Office.context.requirements.isSetSupported('Mailbox', '1.2')) {
        Office.context.ui.openBrowserWindow(link);
    } else {
        window.open(link);
    }
};

const open360 = (event?: React.MouseEvent<HTMLElement>): void => {
    openLink(getEnvironment360URL(),event) ;
};



const Header: React.FC<{ children: ReactNode }>= ({ children }) => {

    const openHelp = async () => {
        const configInfo : IConfigInfo =  await getConfigs();
        window.open(i18n.t('HelpPageUrl', { p360version: formatVersionForHelpUrl(configInfo.VersionOf360ForHelp)}), "_blank");
    };

  const classes = useStyles();
    return (
        <div>

        <header className={classes.root}>
            <div className={classes.headerItems}>
                <div>
                         {/* place holder for home button */}
                </div>
                <div>
                    <Globe24Regular className={classes.item} onClick={() => open360()}/>
                    <QuestionCircle24Regular className={classes.item} onClick={() => openHelp()}/>
                </div>
            </div>
        </header>
         <div className={classes.childrenroot}>
         {children}
        </div>
        </div>
    );
}

export default Header;

