import React from 'react';
import { getEnvironment360URL } from '../../services/configs/appConfig';

const ArchiveMailIframe: React.FC = () => {

    const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

    const docRecno = new URLSearchParams(window.location.search).get("docno");

    console.log('docno found is ', docRecno);

    const src =
    getEnvironment360URL() +
     "/locator/DMS/Dialog/ConnectedDocumentDialog?SAVEAS=1&MoveTo=1&UnregisteredDoc=true&dialog=modal&dialogCloseMode=WINDOWNORETURN&dialogmode=true&dropmode=single&dialogFromAddin=true&_navigationtarget=self&recno="+docRecno;



    return (
        <iframe
            ref={iframeRef}
            src={src}
            width={'100%'} // Dynamically set iframe width
            height={'500px'}     // Set iframe height
            style={{ border: 'none' }} // Optional: Remove iframe border for a cleaner look
            title="Resizable iframe"
        />
    );
}


export default ArchiveMailIframe;
